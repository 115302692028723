// ----------这里将放一些所有页面公用的方法----------

// ----------时间戳函数转换时间----------
export function dateSwitch (nos, format) {
    let format_c = format
    let now = new Date(parseInt(nos) * 1000)
    let year = now.getFullYear();  //取得4位数的年份
    let month = now.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
    let date = now.getDate();      //返回日期月份中的天数（1到31）
    let hour = now.getHours();     //返回日期中的小时数（0到23）
    let minute = now.getMinutes(); //返回日期中的分钟数（0到59）
    let second = now.getSeconds(); //返回日期中的秒数（0到59）
    function p (s) { //补0
        return s < 10 ? '0' + s : s
    }
    //替换数值返回
    return format_c.replace(/YYYY/i, p(year)).replace(/MM/i, p(month)).replace(/DD/i, p(date)).replace(/HH/i, p(hour)).replace(/MIN/i, p(minute)).replace(/SS/i, p(second))
}



// ----------对象转换数组----------
export function ObjToArr (data) {
    let arr = []
    for (const key in data) {
        arr.push(data[key])
    }
    return arr
}

// ----------防抖----------
export function debounce (fn, t) {
    let delay = t || 500    //防抖间隔时间
    let timer   //创建防抖定时器
    return function () {
        let args = arguments
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            timer = null
            fn.apply(this, args)
        }, delay);
    }
}
// ----------节流----------

export function throttle (fn, t) {
    let last;   /* 是否执行了一次 */
    let timer;  /* 定时器函数 */
    let interval = t || 500;    /* 定义的时间*/
    return function () {    /* 返回函数 */
        let args = arguments;   /*  获取argument对象，用与传递函数*/
        let now = +new Date();  /* 获取当前时间戳 */
        if (last && now - last < interval) {    /* 如果已经执行了一次，且小于500ms */
            clearTimeout(timer);    /* 结束计时器 */
            timer = setTimeout(() => {  /* 开始计时器 */
                last = now;
                fn.apply(this, args);
            }, interval);
        } else {    /* 如果没有执行过，或时间超过定义的时间 */
            last = now;
            fn.apply(this, args);
        }
    };
}
