<template>
  <div class="comeOut"
       v-loading.fullscreen.lock="fullscreenLoading">

    <!-- 弹出层 -->
    <div class="pop">
      <el-dialog title="添加认领单"
                 custom-class='addClaimOrder'
                 v-model="rl_dialog"
                 width="65%">
        <el-form :model="claimData"
                 label-width="100px">
          <el-form-item label="所属用户UID:"
                        label-width="130px">
            <el-input class="claimData_input"
                      v-model="claimData.uid" placeholder="收件信息中有才填"></el-input>
          </el-form-item>
          <el-form-item label="快递单号:"
                        label-width="100px">
            <el-input class="claimData_input"
                      v-model="claimData.claim_number"></el-input>
          </el-form-item>
          <el-form-item label="快递公司:"
                        label-width="100px">
            <el-select v-model="rk_data.kdname"
                       placeholder="请选择快递公司"
                       filterable>
              <el-option v-for="item in allKD100"
                         :key="item.code"
                         :label="item.name"
                         :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="装箱单/物品:"
                        label-width="150px">
            <div class="zxdInfo">
              <el-button @click="addZXD()"
                         size="mini">

                添加箱
              </el-button>
              <el-table :data="rk_data.zxd"
                        style="width: 100%; "
                        stripe>
                <el-table-column label="箱号"
                                 width="50"
                                 type="index">

                </el-table-column>
                <el-table-column label="长度"
                                 width="110"
                                 height='50'>
                  <template #default="scope">
                    <el-input v-model="scope.row.chang"
                              type="number"
                              placeholder="CM"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="宽度"
                                 width="110"
                                 height='50'>
                  <template #default="scope">
                    <el-input v-model="scope.row.kuan"
                              type="number"
                              placeholder="CM"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="高度"
                                 width="110"
                                 height='50'>
                  <template #default="scope">
                    <el-input v-model="scope.row.gao"
                              placeholder="CM"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="重量"
                                 width="110"
                                 height='50'>
                  <template #default="scope">
                    <el-input v-model="scope.row.weight"
                              type="number"
                              placeholder="KG"></el-input>
                  </template>
                </el-table-column>

                <el-table-column label="子单号"
                                 height='50'>
                  <template #default="scope">
                    <el-input v-model="scope.row.zdnum"
                              placeholder="(可选)请输入子单号">
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作"
                                 height='50'>
                  <template #default="scope">
                    <el-button @click="removeZXD(scope.$index)"
                               size="mini"
                               type="danger"><i class="el-icon-delete"></i></el-button>
                    <el-button @click="removeZXD(scope.$index)"
                               size="mini"
                               type="danger"><i class="el-icon-delete"></i></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="rl_dialog = false"
                       size="mini">取 消</el-button>
            <el-button type="primary"
                       size="mini"
                       @click="claim_order_full">确 定</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog title="请添加附加费"
                 v-model="additionalShow"
                 width="500px">
        <el-form>
          <el-form-item label="物流单号:"
                        v-if="!getOrderData.order"
                        label-position='top'>
            <el-input v-model="additionalWlnum"
                      placeholder='请输入物流单号'></el-input>
          </el-form-item>
          <el-form-item label="附加费信息:"
                        label-position='top'>
            <div class="item_view">
              <div class="view_list">
                <span>名称:</span>
                <el-select v-model="additionalInfo"
                           placeholder="显示出的名称">
                  <el-option label="超长附加费"
                             value="超长附加费">
                  </el-option>
                  <el-option label="超重附加费"
                             value="超重附加费">
                  </el-option>
                </el-select>

              </div>
              <div class="view_list">
                <span>金额:</span>
                <el-input v-model.number="additionalPrice"
                          type="number"
                          placeholder='附加费金额（RMB）'></el-input>
              </div>

            </div>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="additionalShow = false"
                     size="mini">取 消</el-button>
          <el-button type="primary"
                     size="mini"
                     @click="addAdditional()">确 定</el-button>
        </template>
      </el-dialog>

      <el-dialog title="其他操作"
                 v-model="otherOperation"
                 width="400px">
        <div class="other_view">
          <el-button type="primary"
                     size="mini"
                     @click="showAddPrice">添加附加费</el-button>
          <el-button type="primary"
                     size="mini"
                     @click="skip_print_view">打印票单</el-button>
          <el-button type="primary"
                     size="mini"
                     @click="returnPage()">继续出库</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 弹出层end -->

    <div class="comeOut_top">
      <el-radio-group v-model="goOut"
                      @change="tabData">
        <el-radio-button :label='true'>入库</el-radio-button>
        <el-radio-button :label='false'>出库</el-radio-button>
      </el-radio-group>
      <div>
        <el-button @click="showAddPrice"
                   size="mini">补添附加费</el-button>
        <el-button @click="supplementPrint"
                   size="mini">补打印票单</el-button>
        <el-button @click="manualPrint"
                   size="mini">手动打印单</el-button>
        <el-button @click="showManualInput"
                   size="mini">手动填写</el-button>
      </div>
    </div>
    <div class="comeOut_bottom">
      <!--默认显示 -->
      <el-empty :description="'请扫描快递单号或手动填写 '+ (goOut?'入库':'出库')"
                :image-size='300'
                v-if="getOrderData==''&&!claim"></el-empty>

      <!--没有找到数据，可以添加为认领单 -->
      <el-empty :description="`没有找到快递单号：${manualInput.input}`"
                :image-size='300'
                v-if="claim&&goOut">
        <el-button type="primary"
                   size='mini'
                   @click="showAddClaim">添加认领单</el-button>
      </el-empty>

      <!-- 有入库数据 -->
      <transition name="el-zoom-in-top">
        <div class='be_put_storage_main'
             v-if="getOrderData!=''&&goOut">
          <el-descriptions class="margin-top"
                           direction="vertical"
                           style="width:100%"
                           :title="'请核对与完善入库信息 - '+(getOrderData.order.type==1?'商业件':'集运件')"
                           :column="4"
                           border>
            <template #extra>
              <el-button type="primary"
                         size="small"
                         @click="confirm_rk">提交</el-button>
            </template>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-tickets"></i>
                快递单号
              </template>
              {{manualInput.input}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-mobile-phone"></i>
                下单用户
              </template>
              {{getOrderData.order.jjname}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-location-outline"></i>
                包裹数量
              </template>
              {{getOrderData.order.bgnum}}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-tickets"></i>
                请选择快递公司
              </template>
              <el-select v-model="rk_data.kdname"
                         placeholder="(可选)请选择快递公司"
                         filterable>
                <el-option v-for="item in allKD100"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-descriptions-item>
          </el-descriptions>

          <div class="zxdInfo">
            <el-button @click="addZXD()"
                       size="mini"
                       type="success">
              添加箱
            </el-button>
            <el-table :data="rk_data.zxd"
                      :default-expand-all=" true"
                      style="width: 100%; "
                      stripe>
              <el-table-column type="expand">
                <template #default="props">
                  <div class="expand_btn">
                    <el-button size="mini"
                               @click="addGoodS(props.$index)"
                               type="success">
                      添加物品
                    </el-button>
                  </div>
                  <el-table :data="props.row.wpinfo"
                            style="width: 100%;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) ">
                    <el-table-column label="物品中文名"
                                     :key="props.row.name"
                                     width="120">
                      <template #default="scope">
                        <el-input v-model='scope.row.name'
                                  placeholder="中文"></el-input>
                      </template>
                    </el-table-column>

                    <el-table-column label="英文名"
                                     width="100">
                      <template #default="scope">
                        <el-input v-model='scope.row.enname'
                                  placeholder='English'></el-input>
                      </template>
                    </el-table-column>

                    <el-table-column label="物品类型"
                                     width="110">
                      <template #default="scope">
                        <el-select v-model="scope.row.wptype"
                                   placeholder="点击选择"
                                   filterable>
                          <el-option v-for="item in goodsInfo"
                                     :key="item.id"
                                     :label="item.name"
                                     :value="item.id">
                            <span style="widht:100%;display:flex">{{ item.name}}</span>
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>

                    <el-table-column label="物品数量"
                                     width="100">
                      <template #default="scope">
                        <el-input v-model.number='scope.row.num'
                                  @change="editGoodsPrice(scope.row)"
                                  placeholder="数量"></el-input>
                      </template>
                    </el-table-column>

                    <el-table-column label="数量单位"
                                     width="120"
                                     type="index">
                      <template #default="scope">
                        <el-select v-model="scope.row.unit"
                                   placeholder="请选择">
                          <el-option v-for="(item,index) in unitData"
                                     :key="index"
                                     :label="item"
                                     :value="item">
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>

                    <el-table-column prop="unitprice"
                                     label="物品单价"
                                     width="120">
                      <template #default="scope">
                        <el-input v-model.number='scope.row.unitprice'
                                  @change="editGoodsPrice(scope.row)"
                                  placeholder="单价"></el-input>
                      </template>
                    </el-table-column>

                    <el-table-column prop="unitprice"
                                     label="物品总价">
                      <template #default="scope">
                        <span style='color:#409eff'>
                          {{scope.row.totalamount}}
                        </span>
                      </template>
                    </el-table-column>

                    <el-table-column prop="unitprice"
                                     label="操作">
                      <template #default="scope_1">
                        <el-button type="danger"
                                   size="mini"
                                   @click="deleteGoods(props.$index,scope_1.$index)">删除</el-button>
                      </template>
                    </el-table-column>

                  </el-table>
                </template>
              </el-table-column>
              <el-table-column label="箱号"
                               width="50"
                               type="index">

              </el-table-column>
              <el-table-column label="长度"
                               width="110"
                               height='50'>
                <template #default="scope">
                  <el-input v-model="scope.row.chang"
                            placeholder="CM"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="宽度"
                               width="110"
                               height='50'>
                <template #default="scope">
                  <el-input v-model="scope.row.kuan"
                            placeholder="CM"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="高度"
                               width="110"
                               height='50'>
                <template #default="scope">
                  <el-input v-model="scope.row.gao"
                            placeholder="CM"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="重量"
                               width="110"
                               height='50'>
                <template #default="scope">
                  <el-input v-model="scope.row.weight"
                            placeholder="KG"></el-input>
                </template>
              </el-table-column>

              <el-table-column label="子单号"
                               height='50'>
                <template #default="scope">
                  <el-input v-model="scope.row.zdnum"
                            placeholder="(可选)请输入子单号">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作"
                               height='50'>
                <template #default="scope">
                  <el-button @click="removeZXD(scope.$index)"
                             size="mini"
                             type="danger"><i class="el-icon-delete"></i></el-button>
                  <el-button @click="copyBox(scope.row)"
                             size="mini"
                             type="success"><i class="el-icon-document-copy"></i></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-collapse v-model="activeNames"
                       class="goodinfo">
            <el-collapse-item title="物品说明"
                              name="1">
              <div>
                <el-table :data="goodsInfo"
                          style="width: 100%;padding-bottom: 70px;">
                  <el-table-column prop="name"
                                   label="物品名"
                                   width="180">
                  </el-table-column>
                  <el-table-column prop="info"
                                   label="说明">
                  </el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </transition>

      <!--有出库数据 -->
      <transition name="el-zoom-in-top">
        <div class="be_put_storage_main"
             v-if="getOrderData!=''&&!goOut">
          <el-descriptions title="请核对与完善出库信息"
                           direction="vertical"
                           :column="6"
                           border>
            <template #extra>
              <el-button type="primary"
                         size="mini"
                         @click="ck_message">提交</el-button>
            </template>
            <el-descriptions-item label="物流单号"> {{manualInput.input}}</el-descriptions-item>

            <el-descriptions-item label="出库渠道">
              <el-select v-model="ckDitch"
                         placeholder="请选择">
                <el-option v-for="item in ckDitchList"
                           :key="item.id"
                           :label="item.channelname"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item label="出库司机">
              <el-select v-model="ck_data.driverid"
                         placeholder="请选择">
                <el-option v-for="item in driver"
                           :key="item.id"
                           :label="item.name+' -'+item.mobile"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item label="装柜柜号">
              <el-select v-model="ck_data.lcno"
                         value-key='id'
                         placeholder="请选择柜子">
                <el-option v-for="item in cabinetList"
                           :key="item.id"
                           :label="item.lcno"
                           :value="item">
                </el-option>
              </el-select>
              <!-- <el-input v-model="ck_data.lcno"></el-input> -->
            </el-descriptions-item>
            <el-descriptions-item label="是否出整票">
              <div>
                <el-checkbox v-model="isAll">出整票</el-checkbox>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="打印票单">
              <el-button @click="skip_print_view()"
                         class="skipPrintBtn"
                         size="mini"
                         type="primary">
                进入打印
              </el-button>
            </el-descriptions-item>

            <el-descriptions-item label="外部司机备注"
                                  :span="5"
                                  v-if="ck_data.driverid===0">
              <el-input type="textarea"
                        :rows="3"
                        placeholder="请输入司机姓名+手机号码"
                        v-model="ck_data.remark">
              </el-input>
            </el-descriptions-item>

          </el-descriptions>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>
import { ObjToArr } from '@/assets/common'
import { posyOutCome, postAppAdditional, getLclist, rkWptype } from '@/request/api'
export default {
  name: 'comeOut',
  data () {
    return {
      // -------------是否整票出库-----------
      isAll: true,
      // -------------柜子列表-----------
      cabinetList: [],
      // -------------出库数据-----------
      ck_data: {
        driverid: '',
        remark: '',
        lcno: ''
      },
      // -------------出库渠道-----------
      ckDitch: '',
      // -------------出库渠道list-----------
      ckDitchList: [],
      //-------------更多操作-----------
      otherOperation: false,
      activeNames: '2',
      //-------------物品类型-----------
      goodsInfo: [],
      // -------------仓库添加附加费-----------
      additionalShow: false,
      // -------------补添附加费-名称-----------
      additionalInfo: '',
      // -------------补添附加费-金额-----------
      additionalPrice: '',
      // -------------补添加附加费-物流单号-----------
      additionalWlnum: '',
      //-------------入库数据-----------
      rk_data: {
        kdname: '',
        zxd: [{
          chang: '',
          kuan: '',
          gao: '',
          weight: '',
          zdnum: '',
        }],
        wpinfo: []
      },
      //-------------认领快递数据------------------
      claimData: {
        claim_number: '',                //单号
      },
      // ----------是否显示认领----------
      rl_dialog: false,
      // ----------是否显示添加认领件----------
      claim: false,
      // ----------获取到的订单数据----------
      getOrderData: '',
      // ----------快递100数据----------
      allKD100: [],
      // ----------手动输入----------
      manualInput: {
        show: false,
        input: ''
      },
      // ----------加载电话----------
      fullscreenLoading: false,
      // ----------扫描枪获取的值----------
      kdnum: '',
      // ----------出库（false）或者入库（true）----------
      goOut: true,
      // ----------是否开启扫码----------
      start_scan: true,
      // ----------仓库ID----------
      wid: ''
    }
  },
  computed: {
    unitData () {
      return this.$store.state.numberUnit
    }
  },
  methods: {
    //   -------复制箱-------
    copyBox (row) {
      let data = JSON.parse(JSON.stringify(row))
      for (const key in data.wpinfo) {
        data.wpinfo[key].id = ''
      }

      this.rk_data.zxd.push(data)
    },
    // ------------- 修改物品总价-----------
    editGoodsPrice (row) {
      console.log(row.totalamount = (row.unitprice * row.num).toFixed(2));

    },
    // ------------- 手动打印-----------
    manualPrint () {
      this.$router.push({
        name: 'printView',
        query: {
          manual: true
        }
      })
    },
    // -------------补打印发票-----------
    async supplementPrint () {
      this.$prompt('补打印发票', '请输入物流单号', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        let res = await posyOutCome({
          ckid: this.wid,
          op: 'outck',
          pd: 'orderinfo',
          wlnum: value
        })
        if (res.errno == 0) {
          this.$router.push(
            {
              name: 'printView',
              query: {
                id: res.orderid
              }
            }
          )
        } else {
          this.$message.error(res.message)
        }
      }).catch(() => {

      });
    },
    // -------------出库数据检查-----------
    ck_data_examine () {
      let falg = true
      for (const key in this.ck_data) {
        if (this.ck_data[key] === '') {
          if (key == 'remark' && this.ck_data.driverid == '0') {
            return falg = false
          } else if (key == 'driverid') {
            return falg = false
          }
        }
      }
      return falg
    },
    // -------------出库-----------
    async deliveryCargoStorage () {

      this.$confirm(this.isAll ? `您选择了整票出库，如果此订单还有其他包裹请直接放在出库区，无需再次扫描,此包裹共${this.getOrderData.order.bgnum}箱。` : `您选择了单箱出库，此订单共${this.getOrderData.order.bgnum}箱,请继续扫描其他箱。`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.fullscreenLoading = true
        let res = await posyOutCome({
          ckid: this.wid,
          op: 'outck',
          pd: 'okout',
          lcid: this.ck_data.lcno.id,
          lcno: this.ck_data.lcno.lcno == '无需柜子' ? '' : this.ck_data.lcno.lcno,
          wlnum: this.isAll ? "" : this.manualInput.input,
          bak: this.ck_data.driverid === '0' || this.ck_data.driverid === 0 ? this.ck_data.remark : '',
          orderid: this.isAll ? this.getOrderData.order.id : '',
          sjuid: this.ck_data.driverid
        })
        if (res.errno == 0) {
          this.$message.success('出库成功')
          setTimeout(() => {
            location.reload();
            this.fullscreenLoading = false
          }, 2000);
        } else {
          this.$message.error(res.message)
          this.fullscreenLoading = false
        }
      })

    },
    // -------------出库提示-----------
    ck_message () {
      if (this.ck_data_examine()) {
        this.$confirm(`确定要出库${this.kdnum}的订单吗?请确定已经打印了票单.`, '确定出库', {
          confirmButtonText: '出 库',
          cancelButtonText: '取 消',
          type: 'warning',
        }).then(() => {

          this.deliveryCargoStorage()
        }).catch(() => {

        });
      } else {
        this.$message.error('请填写完整数据')
      }

    },
    // -------------切换数据-----------
    tabData () {
      this.getOrderData = '';
      this.kdnum = '';
      this.allKD100 = '';
    },
    // -------------进入打印界面-----------
    skip_print_view () {
      this.fullscreenLoading = true;
      this.$router.push(
        {
          name: 'printView',
          query: {
            id: this.getOrderData.order.id
          }
        }
      )
      this.fullscreenLoading = false;
    },
    // -------------刷新页面-----------
    returnPage () {
      this.$router.go(0)
    },
    // -------------提交入库-----------
    async setBePutInStorage () {
      this.fullscreenLoading = true
      let res = await posyOutCome({
        op: 'ruku',
        ckid: this.wid,
        pd: 'okruku',
        orderid: this.getOrderData.order.id,
        kdname: this.getOrderData.order.type == 2 ? '' : this.rk_data.kdname,
        zxdinfo: JSON.stringify(this.rk_data.zxd),
      })
      this.fullscreenLoading = false
      if (res.errno == 0) {
        this.$message.success(res.message)
        if (this.getOrderData.order.type == 1) {
          this.otherOperation = true
        } else {
          setTimeout(() => {
            this.$router.go(0)
          }, 1000);
        }
      } else {
        this.$message.error(res.message)
      }
      this.fullscreenLoading = false
    },
    // -------------检测物品info是否为空----------
    detection_wpinfo_null (data) {
      if (!data) {
        return false
      }
      for (const key in data) {
        for (const k in data[key].wpinfo) {
          let { name, enname, wptype, num, unit, unitprice } = data[key].wpinfo[k]
          if (!name || !enname || wptype == undefined || !num || !unit || !unitprice) {
            return false
          }
        }
      }


      return true
    },
    // -------------确定提交入库数据-----------
    confirm_rk () {
      if (this.detection_Data_null(this.rk_data.zxd)) {
        if (this.detection_wpinfo_null(this.rk_data.zxd)) {
          this.setBePutInStorage()
        } else {
          return this.$message.error('请确认物品数据是否填写完全')
        }
      } else {
        return this.$message.error('请确认装箱单数据是否填写完全')
      }
    },
    // -------------删除物品-----------
    deleteGoods (index, k) {
      this.$confirm('此操作将永久删除该物品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.rk_data.zxd[index].wpinfo.splice(0, parseInt(k, 10) + 1)
        this.$message.success('删除成功')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // -------------添加物品-----------
    addGoodS (index) {
      if (!this.rk_data.zxd[index].wpinfo) {
        this.rk_data.zxd[index].wpinfo = []
      }
      this.rk_data.zxd[index].wpinfo.push({
        name: '',
        enname: '',
        wptype: '',
        num: '',
        unit: '',
        unitprice: '',
      })

    },
    // -------------切换清除数据-----------
    tab_eliminate_data () {
    },
    // -------------显示添加附加费-----------
    showAddPrice () {
      this.additionalShow = true
    },
    // -------------添加附加费-----------
    async addAdditional () {
      let res = await postAppAdditional({
        ckid: this.wid,
        wlnum: this.getOrderData.order ? '' : this.additionalWlnum,
        orderid: this.getOrderData.order ? this.getOrderData.order?.id : '',
        otherprice: this.additionalPrice,
        otherpriceinfo: this.additionalInfo
      })

      if (res.errno == 1) {
        this.$message.error(res.message)
        setTimeout(() => {
          this.$router.go(0)
        }, 1000);
      } else {
        this.$message.success(res.message)
        this.additionalWlnum = '';
        this.additionalPrice = '';
        this.additionalInfo = '';
      }

      this.additionalShow = false
    },
    // -------------提交认领-----------
    async setClaim () {
      this.fullscreenLoading = true
      let res = await posyOutCome({
        ckid: this.wid,
        pd: 'okruku',
        op: 'ruku',
        renorder: 1,
        uid:this.claimData.uid,
        kdnum: this.claimData.claim_number,
        kdname: this.rk_data.kdnum,
        zxdinfo: JSON.stringify(this.rk_data.zxd),
      })
      if (res.errno == 0) {
        this.$message.success(res.message)
        setTimeout(() => {
          this.$router.go(0)
        }, 1000);
      } else {
        this.$message.error(res.message+',或删除UID')
      }
      this.fullscreenLoading = false

      // qwe123123a
    },
    // -------------检测数据是否为空-----------
    detection_Data_null (data) {
      for (const key in data) {
        this.rk_data.zxd[key].no = parseInt(key, 10) + 1
        this.rk_data.zxd[key].tj = parseInt(data[key].chang, 10) * parseInt(data[key].kuan, 10) * parseInt(data[key].gao, 10)
        let { no, chang, kuan, gao, weight } = data[key]
        if (!no || !chang || !kuan || !gao || !weight) {
          return false
        }
      }
      return true
    },
    // -------------认领单数据判断-----------
    claim_order_full () {
      if (this.detection_Data_null(this.rk_data.zxd)) {
        this.setClaim()
      } else {
        this.$message.error('请检查装箱单信息是否填写完整')
      }

    },
    // -------------删除装箱单-----------
    removeZXD (id) {
      this.rk_data.zxd.splice(id, 1)
    },
    // -------------添加装箱单-----------
    addZXD () {
      this.rk_data.zxd.push({
        chang: "",
        gao: "",
        kuan: "",
        weight: "",
        zdnum: "",
        no: parseInt(this.rk_data.zxd.length, 10) + 1
      })
    },
    // ----------显示添加认领单----------
    showAddClaim () {
      this.claimData.claim_number = this.manualInput.input
      this.rl_dialog = true
    },
    // ----------点击显示手动填写----------
    showManualInput () {
      this.$prompt(this.goOut ? '请输入快递单号' : '请输入物流单号', '手动输入 - ' + (this.goOut ? '入库' : '出库'), {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (this.goOut) {
          this.orderData(value)
        } else {
          this.orderData_ck(value)
        }

      }).catch(() => {

      });
    },
    // ----------获取订单对应数据(出库)----------
    async orderData_ck (value) {
      this.fullscreenLoading = true
      let res = await posyOutCome({
        ckid: this.wid,
        op: 'outck',
        pd: 'orderinfo',
        wlnum: value
      })
      if (res.errno == 0) {
        this.ckDitchList = ObjToArr(res.qdlist);
        this.driver = ObjToArr(res.sjlist)
        this.getOrderData = res

        let res_ = await getLclist({
          ckid: this.wid,
          page: 1,
          pageSize: 1000,
          zt: 1
        })
        if (res_.errno == 0) {
          this.cabinetList = res_.lclist
        } else {
          this.cabinetList
        }
        this.cabinetList.push({ id: '', lcno: '无需柜子' })

      } else {
        this.claim = false
        this.$message.error(res.message)
      }
      this.manualInput.input = value
      this.fullscreenLoading = false
    },
    // ----------获取订单对应数据(入库)----------
    async orderData (value) {
      this.fullscreenLoading = true
      let res = await posyOutCome({
        ckid: this.wid,
        op: 'ruku',
        pd: 'kdtooid',
        kdnum: value
      })
      if (res.errno == 0) {
        this.rk_data.zxd = res.zxdinfo
        this.getOrderData = res
        let res_ = await rkWptype({
          orderid: res.orderid
        })
        this.goodsInfo = ObjToArr(res_.wptypelist)

      } else {
        this.claim = true
        this.$message.error(res.message)
      }
      this.manualInput.input = value

      this.allKD100 = res.kd100
      this.fullscreenLoading = false

    }

  },
  async created () {

    let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
    let cklist = JSON.parse(localStorage.getItem('cklist'))

    if (cklist) {
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
    } else {
      await this.$store.dispatch('cklist')
      localStorage.setItem('cklist', JSON.stringify(this.$store.state.warehouse))
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
    }

    if (this.$route.query.type) {
      this.goOut = this.$route.query.type == 1
      if (this.goOut) {
        this.orderData(this.$route.query.wlnum)
      } else {
        this.orderData_ck(this.$route.query.wlnum)
      }
    }

    //页面加载完执行事件
    var code = "";
    var lastTime, nextTime;
    var lastCode, nextCode;
    //用户按下按键执行
    document.onkeydown = (e) => {
      if (this.start_scan) {
        // 保存按键码
        nextCode = e.which;
        // 设置跳过的特殊字段
        if (nextCode == 16) {
          return
        }
        //保存按键时间
        nextTime = new Date().getTime();
        // 如果按键码不为空 且 上一次按键码也不为空 且 两次按键激活时间小等于30毫秒 
        if (lastCode != null && lastTime != null && nextTime - lastTime <= 30) {
          // code值加上当前按下的值并保存
          code += lastCode;
          // 如果两次按键激活时间大于100毫秒
        } else if (lastCode != null && lastTime != null && nextTime - lastTime > 100) {
          // 清除code值
          code = "";
        }
        // 保存这次按键码
        lastCode = e.key;
        // 保存这次按键时间
        lastTime = nextTime;
        //如果用户按下回车
        if (e.which == 13) {
          // 如果当前保存的值大于2位
          if (code.length < 2) {
            //手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有效
            //返回false
            return false;
          }
          this.kdnum = code //要更改的值变成扫码枪的值 this.queryCondition.kdnum可以改成需要更改的值
          if (this.goOut) {
            // this.rk_dispose(code)//入库处理
            this.orderData(code)
          } else {
            this.orderData_ck(code)

            // this.ck_dispose(code)
          }
          code = ""; //清空值
        }
      }

    }
  }

}
</script>

<style lang='scss'>
.comeOut {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .item_view {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    .view_list {
      display: flex;
      width: 45%;
      span {
        width: 50px;
      }
    }
  }
  .addClaimOrder {
    min-width: 1100px;
    .el-dialog__title {
      box-shadow: -2px 9px 0px -4px #409eff;
    }
    .el-form {
      margin-left: 50px;
      .el-form-item {
        margin-bottom: 50px;
      }
      .el-form-item__label {
        text-align: left;
        font-size: 16px;
        padding-left: 10px;
        box-shadow: -14px 0 0px -10px #409eff;
      }
      .claimData_input {
        width: 150px;
        input {
          border-radius: 0;
          border: 0;
          border-bottom: 1px solid #ccc;
        }
        input:focus {
          border-bottom: 1px solid #409eff;
        }
      }
    }
  }
  .comeOut_top,
  .comeOut_bottom {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    padding: 0 20px;
  }
  .comeOut_top {
    max-height: 100px;
    // background-color: pink;
    align-items: center;
    justify-content: space-between;
  }
  .comeOut_bottom {
    overflow: auto;

    justify-content: center;
    align-items: center;
    // background-color: #e2687c;
    .be_put_storage_main {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .expand_btn {
        margin-bottom: 10px;
      }
      .zxdInfo {
        width: 100%;
        margin-top: 30px;
      }
      .goodinfo {
        margin-top: 100px;
      }
    }
  }
}
</style>